import React, { createRef, FunctionComponent, useState } from "react"
import { graphql, Link } from "gatsby"
import { FaAlignJustify, FaTimes } from "react-icons/fa"
import { getImage } from "gatsby-plugin-image"
import { Category } from "../../models/category"
import { Post } from "../../models/post"
import Toc from "../../components/toc/toc"
import Layout from "../../components/layout/layout"
import ReadingProgress from "../../components/reading-progress/reading-progress"
import Bio from "../../components/bio/bio"
import Comments from "../../components/comments/comments"
import SEO from "../../components/seo/seo"
import slugify from "slugify"
import * as S from "./post.style"

interface PostTemplateProps {
  data: {
    primaryCategory: Category | null
    post: Post
  }
  location: Location
}

const PostTemplate: FunctionComponent<PostTemplateProps> = ({
  data,
  location,
}) => {
  const [showToc, setShowToc] = useState<boolean>(false)
  const post = data.post
  const readingProgressRef = createRef<HTMLElement>()
  const primaryCategory = data.primaryCategory
  const toggleToc = () => setShowToc(!showToc)

  return (
    <Layout bigHeader={false}>
      <SEO
        location={location}
        title={post.frontmatter.title}
        publishedAt={post.frontmatter.createdDate}
        updatedAt={post.frontmatter.updatedDate}
        categories={post.frontmatter.categories}
        description={post.frontmatter.description}
        keywords={post.frontmatter.keywords}
        isArticle={true}
        lang="pt-br"
        image={
          post.frontmatter?.featuredImage
            ? post.frontmatter.featuredImage
            : null
        }
      />
      <ReadingProgress
        target={readingProgressRef}
        color={primaryCategory ? primaryCategory.color : undefined}
      />
      <S.PostContainer>
        {post.headings.find(h => h.depth > 1) && (
          <>
            <S.LeftSidebar show={showToc}>
              <S.TocWrapper>
                <Toc onClick={toggleToc} />
              </S.TocWrapper>
            </S.LeftSidebar>
            <S.ToggleTocButton
              role={`button`}
              aria-label={`Toggle table of contents`}
              onClick={toggleToc}
            >
              {showToc ? <FaTimes /> : <FaAlignJustify />}
            </S.ToggleTocButton>
          </>
        )}
        <S.PostContent>
          <article className={`post`} ref={readingProgressRef}>
            <S.PostHeader>
              <S.PostMeta>
                {post.frontmatter.categories.length > 0 && (
                  <Link
                    to={`/categoria/${slugify(post.frontmatter.categories[0], {
                      lower: true,
                    })}`}
                  >
                    {post.frontmatter.categories[0]}
                  </Link>
                )}
                <time dateTime={post.frontmatter.createdDate}>
                  {post.frontmatter.createdDatePretty}
                </time>
              </S.PostMeta>
              <S.PostTitle>{post.frontmatter.title}</S.PostTitle>
            </S.PostHeader>
            {post.frontmatter?.featuredImage && (
              <S.FeaturedImage
                image={getImage(post.frontmatter?.featuredImage)}
                alt={post.frontmatter?.featuredImageAlt}
              />
            )}
            <S.StyledPost
              dangerouslySetInnerHTML={{ __html: post.html }}
              className={`post`}
            />
            <S.PostFooter>
              <p>
                Publicado em&nbsp;
                {post.frontmatter.categories.map((category, index) => (
                  <span key={index}>
                    <S.FooterCategoryLink
                      to={`/categoria/${slugify(category, { lower: true })}`}
                    >
                      {category}
                    </S.FooterCategoryLink>
                    {post.frontmatter.categories.length > index + 1 && <>, </>}
                  </span>
                ))}
                &nbsp;-{" "}
                <time dateTime={post.frontmatter.createdDate}>
                  {post.frontmatter.createdDatePretty}
                </time>
                .
              </p>
              {post.frontmatter.updatedDate !==
                post.frontmatter.createdDate && (
                <p>
                  Última atualização em{" "}
                  <time dateTime={post.frontmatter.updatedDate}>
                    {post.frontmatter.updatedDatePretty}
                  </time>
                  .
                </p>
              )}
            </S.PostFooter>
          </article>
        </S.PostContent>
      </S.PostContainer>
      <S.PostAddition>
        <S.PostAdditionContent>
          <S.BioWrapper>
            <Bio textAlign={`center`} showName={true} />
          </S.BioWrapper>
        </S.PostAdditionContent>
      </S.PostAddition>
      <Comments id={post.id} />
    </Layout>
  )
}

export default PostTemplate

export const query = graphql`
  query PrimaryCategory($postId: String!, $primaryCategory: String!) {
    post: markdownRemark(id: { eq: $postId }) {
      id
      headings {
        depth
      }
      html
      frontmatter {
        title
        path
        categories
        description
        createdDate
        keywords
        createdDatePretty: createdDate(
          formatString: "DD MMMM, YYYY"
          locale: "pt-br"
        )
        updatedDate
        updatedDatePretty: updatedDate(
          formatString: "DD MMMM, YYYY"
          locale: "pt-br"
        )
        featuredImageAlt
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [WEBP]
              quality: 80
            )
          }
        }
      }
    }
    primaryCategory: categories(name: { eq: $primaryCategory }) {
      name
      color
    }
  }
`
