import React, { CSSProperties, FunctionComponent } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import * as S from "./avatar.style"

export interface AvatarProps {
  alt: string
  style?: CSSProperties
}

const Avatar: FunctionComponent<AvatarProps> = ({ alt, style }) => {
  const logo = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "themeAssets" }, name: { eq: "logo" }) {
        childImageSharp {
          gatsbyImageData(width: 55, height: 55)
        }
      }
    }
  `)

  return <S.StyledAvatar image={getImage(logo.file)} alt={alt} style={style} />
}

export default Avatar
