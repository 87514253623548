import styled from "styled-components"
import Theme from "../styles/theme"

export const Container = styled.div`
  width: ${Theme.components.container.width};
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @media (max-width: ${Theme.breakpoints.xl}) {
    padding: 0 20px;
  }
`
